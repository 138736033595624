<template>
  <ConfirmDialog
    maxWidth="500px"
    :value.sync="show"
    title="Powielanie treści"
    showCloseBtn
  >
    <v-layout column align-center mb-2 slot="header" style="width: 100%">
      <DefaultLabel>{{ subtitle }}</DefaultLabel>
    </v-layout>
    <v-form slot="content">
      <v-layout column>
        <HTMLTextArea
          :rules="textRules"
          label="Powielaj treść:"
          placeholder="Wpisz treść do powielania..."
          :value.sync="textToDuplicate"
          dense
          horizontalMargin
          verticalMargin
        ></HTMLTextArea>
      </v-layout>
    </v-form>
    <v-layout slot="footer">
      <v-spacer></v-spacer>
      <v-layout shrink>
        <DefaultButton
          v-for="button in buttons"
          :key="button.name"
          :bgColor="button.color"
          :loading="$data[button.loading]"
          :primary="button.primary"
          @click.prevent="button.action"
          >{{ button.name }}</DefaultButton
        >
      </v-layout>
    </v-layout>
  </ConfirmDialog>
</template>

<script>
import Validators from "@/mixins/Validators";
import ProxyCRUDMixin from "@/mixins/ProxyCRUDMixin";
import PatientService from "@/services/patient.service";

export default {
  mixins: [Validators, ProxyCRUDMixin],
  props: {
    subtitle: {},
    value: {},
    text: {},
    duplication: {},
    patient: {},
    session: {},
    model: {},
  },
  data() {
    return {
      initialDuplication: this.duplication,
      internalText: null,
    };
  },
  components: {
    DefaultButton: () => import("@/components/buttons/DefaultButton"),
    DefaultLabel: () => import("@/components/text/DefaultLabel"),
    ConfirmDialog: () => import("@/components/popups/ConfirmDialog"),
    HTMLTextArea: () => import("@/components/inputs/HTMLTextArea"),
  },
  computed: {
    show: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("update:value", value);
      },
    },
    internalDuplication: {
      get() {
        return this.duplication;
      },
      set(value) {
        this.$emit("update:duplication", value);
      },
    },
    textToDuplicate: {
      get() {
        if (this.internalText) {
          return this.internalText;
        } else if (this.initialDuplication !== "") {
          return this.initialDuplication;
        } else {
          return this.text;
        }
      },
      set(value) {
        this.internalText = value;
      },
    },
    buttons() {
      return this.internalDuplication === ""
        ? [
            {
              name: "Anuluj",
              color: "primary",
              action: () => this.hide(),
            },
            {
              name: "Zapisz",
              loading: "loading",
              color: "primary",
              primary: true,
              action: () => this.addDuplication(),
            },
          ]
        : [
            {
              name: "Uaktualnij",
              loading: "loading",
              color: "primary",
              primary: true,
              action: () => this.updateDuplication(),
            },
            {
              name: "Zakończ",
              loading: "loading",
              color: "error",
              action: () => this.destroyDuplication(),
            },
            {
              name: "Anuluj",
              color: "primary",
              action: () => this.hide(),
            },
          ];
    },
  },
  methods: {
    hide() {
      this.show = false;
    },
    addDuplication() {
      this.beforeRequest();
      PatientService.addDuplication(
        this.patient,
        this.session,
        this.model,
        this.textToDuplicate
      )
        .then(() => {
          this.internalDuplication = this.textToDuplicate;
          this.handleSuccess("Pomyślnie dodano tekst do powielania.");
          this.hide();
        })
        .catch((error) => {
          this.handleError("Coś poszło nie tak.");
          console.log("ERROR", error, error.response);
        });
    },
    updateDuplication() {
      this.beforeRequest();
      PatientService.updateDuplication(
        this.patient,
        this.session,
        this.model,
        this.textToDuplicate
      )
        .then(() => {
          this.internalDuplication = this.textToDuplicate;
          this.handleSuccess("Pomyślnie zaktualizowano tekst do powielania.");
          this.hide();
        })
        .catch((error) => {
          this.handleError("Coś poszło nie tak.");
          console.log("ERROR", error, error.response);
        });
    },
    destroyDuplication() {
      PatientService.destroyDuplication(this.patient, this.session, this.model)
        .then(() => {
          this.internalDuplication = "";
          this.handleSuccess("Pomyślnie wyłączono powielanie tekstu.");
          this.hide();
        })
        .catch((error) => {
          this.handleError("Coś poszło nie tak.");
          console.log("ERROR", error, error.response);
        });
    },
  },
};
</script>